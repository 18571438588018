@import '~nprogress/nprogress.css';
@import '~animate.css';
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html,
body {
  background-color: #fff !important;
}
#root {
  margin: 0 auto;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  font-family: PingFang SC, Microsoft YaHei, serif !important;
}

p {
  margin: 0;
}

@hd: 2px;